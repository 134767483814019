import { FunctionComponent } from 'react';

import { Item } from './Item';

import { pickChilds } from '@/lib/utils';

import { TypeMenuProps } from './types';

/**
 * Menu
 */
export const Menu: FunctionComponent<TypeMenuProps> = ({
  children,
  className = '',
}: TypeMenuProps): JSX.Element => {
  const [, items] = pickChilds(children, Item);

  return (
    <nav className={`w-full ${className}`}>
      <ul className="flex flex-col lg:flex-row">{items}</ul>
    </nav>
  );
};

Menu.displayName = 'Menu';
