import { FunctionComponent } from 'react';
import { Disclosure, Transition } from '@headlessui/react';
import { oneLine } from 'common-tags';

import { CaretDown, ChevronDown } from '@/icons';

import { TypeAccordionProps } from './types';

export const Accordion: FunctionComponent<TypeAccordionProps> = ({
  className,
  title,
  children,
  variant,
  defaultOpen = false,
  font = 'large',
}: TypeAccordionProps) => {
  const Icon = variant !== 'link' ? ChevronDown : CaretDown;

  return (
    <Disclosure
      as="div"
      className={oneLine`
        text-body
        ${font === 'small' ? 'text-body--s' : ''}
        ${font === 'medium' ? 'text-body--m' : ''}
        ${font === 'large' ? 'text-body--l' : ''}
        ${className}
      `}
      defaultOpen={defaultOpen}
    >
      {({ open }) => (
        <>
          <Disclosure.Button
            className={oneLine`
              flex text-left w-full py-6 items-center font-semibold
              ${
                variant === 'link'
                  ? 'text-primary-500 tracking-widest uppercase'
                  : 'justify-between'
              }
              ${variant === 'h2' ? 'text-xl' : 'justify-between'}
            `}
          >
            {variant === 'h2' ? <h2>{title}</h2> : <>{title}</>}
            <Icon
              className={`text-primary-500 ${open ? 'rotate-180' : ''}`}
              width={32}
              height={28}
            />
          </Disclosure.Button>
          <Transition
            enter="transition duration-20 ease-out"
            enterFrom="transform scale-95 opacity-0"
            enterTo="transform scale-100 opacity-100"
            leave="transition duration-20 ease-out"
            leaveFrom="transform scale-100 opacity-100"
            leaveTo="transform scale-95 opacity-0"
            unmount={false}
          >
            <Disclosure.Panel unmount={false} className="py-6">
              {children}
            </Disclosure.Panel>
          </Transition>
        </>
      )}
    </Disclosure>
  );
};

Accordion.displayName = 'Accordion';
