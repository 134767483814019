import { FunctionComponent } from 'react';
import NextLink from 'next/link';

import { ChevronRight } from '@/icons';
import { CardMenu, Container, Link } from '@/atoms';

import { FlyOutProps } from './types';
import { LinkVariant } from '@/atoms/link/types';


/**
 * Primary UI component for user interaction
 */
export const FlyOut: FunctionComponent<FlyOutProps> = ({
  titleLeft,
  menu,
  viewMore,
  cards,
  closed,
}: FlyOutProps) => (
  <Container
    wrapper
    backgroundColor="bg-white"
    className="w-full h-max"
    padding="lg:px-4 lg:py-8"
  >
    <div className="flex flex-row gap-16">
      <div className="flex flex-col gap-6 w-full lg:w-60">
        <Link
          as="button"
          icon="left"
          className="lg:hidden mb-6"
          variant={LinkVariant.CURRENTCOLOR}
          onClick={closed}
          largerFont={true}
        >
          volver
        </Link>

        <p className="hidden lg:block text-subtitle text-subtitle--l">
          {titleLeft}
        </p>

        <ul className="flex flex-col gap-9 lg:gap-4 text-body text-body--m lg:text-body--l">
          {menu.map((item, index: number) => (
            <li className="flex flex-col" key={item.id ?? index}>
              {item.url ? (
                <NextLink
                  href={item.url}
                  prefetch={false}
                  className="flex justify-between w-full items-center hover:opacity-60 text-lg md:text-base"
                >
                  {item.title}

                  <ChevronRight
                    height={24}
                    width={24}
                    className="lg:hidden text-primary-500"
                  />
                </NextLink>
              ) : (
                item.title
              )}
            </li>
          ))}
        </ul>

        {viewMore && (
          <NextLink href={viewMore.url} prefetch={false} passHref legacyBehavior>
            <Link
              icon="right"
              variant={LinkVariant.POSITIVE}
              className="hidden lg:flex"
            >
              Ver todo
            </Link>
          </NextLink>
        )}
      </div>

      <hr className="w-px h-full bg-secondary-200 hidden lg:block" />

      <div className="hidden lg:flex lg:flex-col">
        <p className="text-subtitle text-subtitle--m mb-10">{cards?.field_title}</p>

        <div className="flex gap-10">
          {cards?.field_link_items.map(
            (card, index: number) =>
              card.field_link.url && (
                <NextLink
                  href={card.field_link.url}
                  prefetch={false}
                  passHref
                  key={card.id ?? index}
                  legacyBehavior
                >
                  <CardMenu as="a" title={card.field_title} media={card.field_image} />
                </NextLink>
              )
          )}
        </div>
      </div>
    </div>
  </Container>
);
