import { Menu } from './Menu';
import { Item } from './Item';

export type TypeMenu = typeof Menu & {
  Item: typeof Item;
};

(Menu as TypeMenu).Item = Item;

Item.displayName = 'Menu.Item';

export type { TypeMenuProps } from './types';
export default Menu as TypeMenu;
