import { useRouter } from 'next/router';
import { useEffect } from 'react';

/**
 * Hook to execute a callback when a route changed start.
 * @param  {() => void} callback Function that will be executed when route is started.
 */
export const useRouteChangeStart = (callback: () => void) => {
  const router = useRouter();

  useEffect(() => {
    router.events.on('routeChangeStart', callback);

    return () => router.events.off('routeChangeStart', callback);
  }, [callback, router.events]);
};
