import { IconType, defaultProps } from './types';

/**
 * Icono ChevronRight
 */
export const ChevronRight = ({
  className,
  width,
  height,
  color = defaultProps.color,
}: IconType): JSX.Element => (
  <svg
    {...(className ? { className: className } : {})}
    {...(width ? { width: width } : {})}
    {...(height ? { height: height } : {})}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
  >
    <path
      d="m13.061 12.636-4.95-4.95 1.414-1.414 6.364 6.364L9.525 19l-1.414-1.414 4.95-4.95Z"
      fill={color}
    />
  </svg>
);
