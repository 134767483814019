import { ComponentPropsWithRef, Ref, forwardRef } from 'react';
import Image from 'next/image';

import '@/lib/utils/forwardRef';

import { TypeCardMenuProps } from './types';

import FallBackIMG from '@/assets/images/fallback-product-card.jpg';

/**
 * CardMenu
 */
const CardMenuBase = <T extends React.ElementType = 'div'>(
  {
    className = '',
    media,
    title,
    as,
    ...props
  }: TypeCardMenuProps<T> &
    Omit<ComponentPropsWithRef<T>, keyof TypeCardMenuProps<T>>,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ref: Ref<any>
) => {
  const image = media?.field_media_image;

  if (!image?.uri) {
    return null;
  }


  const HTMLTag = as || 'div';
  const srcWithFallback =
    image.uri && image.uri.url !== '' ? image.uri.url : FallBackIMG.src;

  return (
    <HTMLTag
      className={`flex flex-col justify-center gap-6 px-2 py-4 ${className}`}
      ref={ref}
      {...props}
    >
      <div className="w-[180px] h-[148px] relative">
        <Image
          src={srcWithFallback}
          alt={image.alt ?? ''}
          className="w-full h-full object-contain object-center absolute inset-0"
          draggable="false"
          sizes="100vw"
          fill
        />
      </div>

      <p className="text-button text-center text-text-secondary">{title}</p>
    </HTMLTag>
  );
};

export const CardMenu = forwardRef(CardMenuBase);
