import { FunctionComponent, useState } from 'react';
import Link from 'next/link';
import { oneLine } from 'common-tags';

import { ChevronRight } from '@/icons';

import { useRouteChangeStart } from '@/hooks';

import { TypeItem } from './types';

export const Item: FunctionComponent<TypeItem> = ({
  href,
  title,
  children,
  className = '',
}: TypeItem): JSX.Element => {
  const [isOpen, setOpen] = useState(false);
  useRouteChangeStart(() => setOpen(false));

  return (
    <li className={`group ${className} py-5 lg:py-0 lg:px-8`} data-menu>
      <div className="text-card text-card--s uppercase flex lg:px-1 lg:py-3 lg:border-b lg:border-transparent lg:group-hover:border-primary-500 only:border-none">
        {href ? (
          <Link
            href={href}
            prefetch={false}
            className="flex justify-between w-full items-center"
          >
            {title}
            <ChevronRight
              height={24}
              width={24}
              className="lg:hidden text-primary-500"
            />
          </Link>
        ) : (
          <div
            className="flex justify-between w-full items-center"
            onClick={() => setOpen((isOpen) => !isOpen)}
          >
            {title}
            <ChevronRight
              height={24}
              width={24}
              className="lg:hidden text-primary-500"
            />
          </div>
        )}
      </div>

      {children && (
        <div
          className={oneLine`
            ${isOpen ? 'flex' : 'hidden '} lg:hidden
            absolute inset-0 w-full bg-white text-text-primary lg:static lg:top-0 lg:group-hover:flex lg:group-hover:absolute lg:group-hover:right-0 lg:group-hover:left-0 lg:group-hover:justify-center lg:group-hover:pt-header lg:group-hover:-z-10
          `}
          data-submenu-open={isOpen}
        >
          <children.type {...children.props} closed={() => setOpen(false)} />
        </div>
      )}
    </li>
  );
};

Item.displayName = 'Item';
