export const routes = {
  home: '/',
  blog: {
    index: '/es/escuela-del-jamon',
    item: (item: string) => `/es/escuela-del-jamon/${item}`,
  },
  // contact: '/es/contacto',
  product: {
    index: '/es/productos-ibericos',
    item: (item: string) => `/es/productos-ibericos/${item}`,
  },
};
